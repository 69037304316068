import React from "react";
import { connect } from "react-redux";
import { userLogin } from "../../state/actions/authActions";
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import Notiflix from 'notiflix';
import { Link } from "react-router-dom";
import axios from "axios";
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			isLoading: false,
		};
	}

	handleInput = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = async (e) => {
		this.setState({isLoading: true})
		e.preventDefault();
		try {
			const url = `${process.env.REACT_APP_HOMEURL}/password-reset`;
			const { data } = await axios.post(url, {email:this.state.email});
			this.setState({isLoading: false})
			Notiflix.Notify.success('Please Check Your Email to resetPassword');
		} catch (error) {
			this.setState({isLoading: false})
          Notiflix.Notify.warning('Invalid Credentials!!');
		}
		//preventDdefault
	};

	render() {
		return (
			<section className="girl_image"style={{backgroundSize:'cover',height:'91vh'}}>
				<div className="container">
					{this.state.isLoading?Loading.dots(): Loading.remove()}
					<div className="row">
						<div className="col-sm-9 col-md-7 col-lg-5 mx-auto position-absolute mt-5" style={{right:"0"}}>
							<div className="text-white card-signin my-5">
								<div className="card-body">
									<h1 className="text-center mb-4" style={{fontSize:"1.5rem"}}>
										Reset Password
									</h1>
									<form className="form-signin">
											<input
												onChange={(e) =>
													this.handleInput(e)
												}
												name="email"
												type="email"
												id="inputEmail"
												className="form-control p-4 m-2"
												placeholder="Email address"
												value={this.state.email}
												required
												autoFocus            
												style={{borderRadius:'30px'}}
											/>

										<button
											onClick={(e) =>
												this.handleSubmit(e)
											}
											className="my-4 btn btn-lg btn-primary btn-block text-uppercase"
											type="primary"
										>
											Send OTP
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

// function mapStateToProps(state)
function mapStateToProps(state) {
	return { currentUser: state.currentUser };
}

export default connect(mapStateToProps)(ResetPassword);
