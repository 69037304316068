import React from "react";
import "./assets/stylesheets/main.scss";
import "bulma/css/bulma.css";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import LandingPage from "./views/common/LandingPage";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import CreateQuiz from "./views/quiz/createQuiz";
import Header from "./views/common/Header";
import AttemptQuiz from "./views/quiz/attemptQuiz";
import Login from "./views/auth/login";
import Signup from "./views/auth/signup";
import PaymentRoute from "./views/auth/PaymentRoute";
import EditQuiz from "./views/quiz/editQuiz";
import { identifyUser } from "./state/actions/authActions";
import Profile from "./views/auth/Profile";
import QuizResult from "./views/quiz/QuizResult";
import Contact from "./views/auth/Contact";
import Admin_Dashboard from "./views/quiz/Admin_Dashboard";
import ResetPassord from "./views/auth/ResetPassord";
import PaymentSuccess from "./views/auth/PaymentSuccess";
import ChangePassword from "./views/auth/ChangePassword";
import Footer from "./views/common/Footer";
import TermsandCondition from "./views/common/TermsandCondition";
import AboutUs from "./views/common/AboutUs";
require("dotenv").config();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisclamerClose: false,
    };
  }

  componentDidMount() {
    if (localStorage["auth-token"]) {
      this.props.dispatch(identifyUser());
    }
  }

  render() {
    const ProtectedRoutes = () => {
      return (
        <Switch>
          <Route exact path="/signup" component={Signup} />
          <Route path="/quiz/pay" component={PaymentRoute} />
          <Route path="/quiz/new" component={CreateQuiz} />
          <Route exact path="/quiz/:id" component={AttemptQuiz} />
          <Route exact path="/quiz/:id/edit" component={EditQuiz} />
          <Route path="/profile" component={Profile} />
          <Route path="/quizresult" component={QuizResult} />
          <Route path="/contact" component={Contact} />
          <Route path="/adminDasboard" component={Admin_Dashboard} />
          <Route path="/paymentSuccess/:reference" component={PaymentSuccess} />
        </Switch>
      );
    };

    const PublicRoutes = () => {
      return (
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route exact path="/" component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/resetPassword" component={ResetPassord} />
          <Route path="/password-reset/:id/:token" component={ChangePassword} />
          <Route path="/signup" component={Signup} />
          <Route path="/toc" component={TermsandCondition} />
          <Route path="/aboutus" component={AboutUs} />
        </Switch>
      );
    };
    const currentUser = this.props.currentUser;
    return (
      <>
        {currentUser.isAuthReqInProgress ? (
          Loading.dots()
        ) : (
          <>
            <Header />
            {Loading.remove()}
            {currentUser.userInfo ? ProtectedRoutes() : PublicRoutes()}
            <Footer />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return { currentUser: state.currentUser };
}
export default connect(mapStateToProps)(App);
