import React from "react";
import { useParams } from "react-router-dom";

const PaymentSuccess = (props) => {
  const param = useParams();

  // console.log("params: ", props.match.params.reference);
  return (
    <div className="container my-5" style={{ height: "90vh" }}>
      <h1>Payment Succesful, Now Attempt the Quiz</h1>
      <button
        className="btn btn-primary mt-2 mb-4"
        onClick={() => {
          props.history.push("/quiz/64798d12113e9f0032bf864a");
        }}
      >
        Attempt Quiz
      </button>
      <h2 className="mt-5">
        Your reference id- {props.match.params.reference}
      </h2>
    </div>
  );
};

export default PaymentSuccess;
