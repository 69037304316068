import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Notiflix from 'notiflix';

export class Admin_Dashboard extends Component {
  async winner(){
      let win = await axios.get(`${process.env.REACT_APP_HOMEURL}/quiz/63cebff0221f0a29f82ae63e/winner`);
      this.setState({winners:win.data})
  }
  async quizlist(){
    let list = await axios.get(`${process.env.REACT_APP_HOMEURL}/quiz/`);
    this.setState({quizData:list.data.quizzes})
  }
  async deleteQuiz(id){
    Notiflix.Confirm.show(
			'Delete',
			'Do you Want to Delete Quiz',
			'Yes',
			'No',
			async() => {
        await axios.delete(`${process.env.REACT_APP_HOMEURL}/quiz/${id}`);
        Notiflix.Notify.success('Quiz Deleted Succesfully!!');
        window.location.reload(true);
			},
			() => {
			  Notiflix.Notify.failure('Quiz delete Cancelled');
			return;
			},
			);
  }
  constructor(props) {
    super(props);
    this.state={
        winners:[],
        quizData:[]
    }
    this.quizlist();
    this.winner();
  }
  render() {
    if(this.state.quizData.length !== 0){
    }
    return (
      <>
        <div className="p-5 adminDash">
          <div className="row">
            <div className="col">
                <div className="container">
                <h1 className='mt-3'>Dashboard</h1>
                <h5 className='mb-5'>Hi, {this.props.currentUser.userInfo.name} Welcome back</h5>
                <h3>Current Running Quiz</h3>
                {
                    this.state.quizData.map((quiz,index)=>{
                        return(
                          <div className="col-md-3 my-5" key={index}>
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">{quiz.title}</h5>
                              <a className="fa-solid fa-trash mx-3 "style={{cursor:'pointer',color:'black'}} onClick={()=>{this.deleteQuiz(quiz._id)}}></a>
                              <Link className="fa-solid fa-pen-to-square mx-3" style={{cursor:'pointer',color:'black'}} to={`/quiz/${quiz._id}/edit`}></Link>
                            </div>
                          </div>
                        </div>
                        );
                    })
                }
                <h5>Go To</h5>
                <Link onClick={()=>{
                  if(this.state.quizData.length !== 0){
                    Notiflix.Notify.failure('Only One Quiz can run at a Time');
                  }
                }} to={this.state.quizData.length === 0 ? "/quiz/new" : "/adminDasboard" }><button disabled={this.state.quizData.length === 0 ? false : true} className="btn btn-primary my-2">Create Quiz</button></Link>
                </div>
            </div>
            <div className="col">
                <div className="container">
                    <h1 className="my-3">Winner list of the Quiz</h1>
                    {
                        this.state.winners.map((winUPI,index)=>{
                            console.log(winUPI);
                            return(
                                <p className="container my-3">{++index}. {winUPI}</p>
                            );
                        })
                    }
                </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return { currentUser: state.currentUser };
}

export default connect(mapStateToProps)(Admin_Dashboard);
