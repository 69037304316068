import React from 'react';
import { Row} from 'react-bootstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

class LandingPage extends React.Component {
    handleRedirectToSignUp = () => {
        this.props.history.push("/signup");
    }
    
    render() {
        return (
          <div
            className="landingPage"
            style={{
              backgroundImage: "url('/logo_bg_home.jpg')",
              color: "white",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 0 2000px rgb(15 57 45 / 40%)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <Row style={{ alignSelf: "flex-end" }}>
                <Player
                  autoplay
                  keepLastFrame={true}
                  src="https://assets8.lottiefiles.com/packages/lf20_qxemgrnw.json"
                  style={{ height: "400px", width: "400px" }}
                >
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              </Row>
              <Row
                style={{
                  paddingLeft: "50px",
                  marginRight: "`175`px",
                  alignSelf: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="heading1" style={{ padding: "20px" }}>
                  <h1>WORLD ONLINE QURAN QUIZ COMPETITION</h1>
                  <h6 style={{ marginTop: "25px" }}>
                    Join with just Rs 15 and be a winner. This is just a simple
                    Quran competition for the people who loves quran and
                    enthusiast. we are giving rewards for the winner of the
                    quiz.
                  </h6>
                </div>
              </Row>
            </div>
            <div style={{ display: "flex", padding: "15px" }}>
              <Player
                autoplay
                keepLastFrame={true}
                src="https://assets1.lottiefiles.com/packages/lf20_olahoemm.json"
                style={{ height: "300px", width: "300px" }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
              <div style={{ alignSelf: "center" }}>
                <h2>BE A WINNER AND GET YOUR REWARDS</h2>
                <h6>
                  Answer just 5 simple questions and be a winner of{" "}
                  <span class="font-weight-bold">Rs.15000</span>. What are you
                  waiting for ! come on Join it Now...
                </h6>
              </div>
            </div>
            <button
              className="my-5"
              onClick={() => {
                this.handleRedirectToSignUp();
              }}
              style={{
                width: "150px",
                height: "40px",
                marginLeft: "80%",
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#F3D724",
                marginBottom: "30px",
              }}
            >
              Sign Up Now
            </button>
          </div>
        );
    }
}
export default LandingPage;