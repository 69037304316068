import React from 'react'

const Contact = () => {
  return (
    <div className='contact p-5 text-white'>
        <i class="fa-regular fa-address-card my-3" style={{fontSize:'5rem',justifyContent:'center'}}></i>
        <h1>Contact Us</h1>
        <p className='mt-5' style={{fontSize:'20px'}}>Facing Problem? We are here to help</p>
        <p style={{fontSize:'20px',display:'inline'}}>Please Write your queries on email: </p>
        <p style={{fontWeight:'bold',fontSize:'20px',display:'inline'}}>onlinecare@thequrancompetition.com</p>
    </div>
  )
}

export default Contact