import React from "react";
import { connect } from "react-redux";
import axios from "axios";
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });
const Razorpay = require("razorpay");

const PaymentRoute = (props) => {
  const checkoutHandler = async (amount, userId, userName, userEmail) => {
    const {
      data: { key },
    } = await axios.get(`${process.env.REACT_APP_HOMEURL}/payment/getkey`);

    const {
      data: { order },
    } = await axios.post(`${process.env.REACT_APP_HOMEURL}/payment/checkout`, {
      amount,
      userId,
    });
    const options = {
      key,
      amount: "15",
      currency: "INR",
      name: "thequrancompetition",
      description: "Tutorial of RazorPay",
      image: "/favicon.ico",
      order_id: order.id,
      callback_url: `${process.env.REACT_APP_HOMEURL}/payment/verify`,
      prefill: {
        name: { userName },
        email: { userEmail },
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  // If paid can Attempt Quiz
  if (props.currentUser.userInfo.isPaid === true) {
    props.history.push("/quiz/64798d12113e9f0032bf864a");
  }
  if (props.currentUser.userInfo.superUser === true) {
    props.history.push("/adminDasboard");
  }
  return (
    <>
      <div className="container my-3" style={{ height: "90vh" }}>
        <h2 className="my-3">Welcome {props.currentUser.userInfo.name}</h2>
        <p>
          You can start the quiz Competition by paying a small fee and be a
          winner of the Rs10,000.
        </p>
        <button
          id="rzp-button1"
          type="button"
          className="btn btn-primary"
          onClick={() => {
            checkoutHandler(
              15,
              props.currentUser.userInfo._id,
              props.currentUser.userInfo.name,
              props.currentUser.userInfo.email
            );
          }}
        >
          Pay 15
        </button>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return { currentUser: state.currentUser };
}
export default connect(mapStateToProps)(PaymentRoute);
