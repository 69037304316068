import React from "react";
import { Link } from "react-router-dom";
import { userSignup } from "../../state/actions/authActions";
import Notiflix from "notiflix";
import { Loading } from "notiflix/build/notiflix-loading-aio";

class Signup extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    gPay: "",
    isPosting: false,
    successMsg: "",
    errorMsg: "",
    check: false,
    isLoading: false,
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.isPosting) {
      this.setState({ isLoading: false });
      return;
    }

    if (this.state.name.length <= 2) {
      this.setState({ isLoading: false });
      Notiflix.Notify.warning("Name Must be atleast 3 Charecters!");
      return;
    }
    // eslint-disable-next-line
    if (
      !this.state.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState({ isLoading: false });
      Notiflix.Notify.warning("Please enter Correct email adderss!");
      return;
    }
    if (this.state.password.length <= 5) {
      this.setState({ isLoading: false });
      Notiflix.Notify.warning("Password Must be atleast 6 Charecters!");
      return;
    }
    if (this.state.password.length <= 4) {
      this.setState({ isLoading: false });
      Notiflix.Notify.warning("Please enter Correct UPI details");
      return;
    }

    // validation is missing
    // For SigningUp
    var res = await userSignup(this.state);
    console.log("res: ", res);
    if (res) {
      this.setState({ isLoading: false });
      Notiflix.Notify.success("SignedUp Succesfully, Please Login");
      this.props.history.push("/login");
    } else {
      Notiflix.Notify.warning(
        "Please try again later OR try with different eMail"
      );
    }
  };
  render() {
    return (
      <section
        className="girl_image"
        style={{ backgroundSize: "cover", height: "91vh" }}
      >
        <div className="container">
          {this.state.isLoading ? Loading.dots() : Loading.remove()}
          <div className="row">
            <div
              className="col-sm-9 col-md-7 col-lg-5 mx-auto position-absolute"
              style={{ right: "0" }}
            >
              <div className="text-white card-signin my-5">
                <div className="card-body">
                  <h1
                    className="text-center mb-4"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Register
                  </h1>
                  <form className="form-signin">
                    <input
                      type="text"
                      name="name"
                      id="inputUsername"
                      className="form-control p-4 m-2"
                      placeholder="Name"
                      value={this.state.username}
                      onChange={(e) => this.handleChange(e)}
                      required
                      autoFocus
                      style={{ borderRadius: "30px" }}
                    />
                    <input
                      type="email"
                      name="email"
                      id="inputEmail"
                      className="form-control p-4 m-2"
                      value={this.state.email}
                      placeholder="Email address"
                      onChange={(e) => this.handleChange(e)}
                      required
                      style={{ borderRadius: "30px" }}
                    />

                    <input
                      type="password"
                      name="password"
                      id="inputPassword"
                      className="form-control p-4 m-2"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={(e) => this.handleChange(e)}
                      required
                      style={{ borderRadius: "30px" }}
                    />
                    <input
                      type="text"
                      name="gPay"
                      id="inputgPay"
                      className="form-control p-4 m-2"
                      placeholder="Gpay Address - Please enter the correct UPI address"
                      value={this.state.gPay}
                      onChange={(e) => this.handleChange(e)}
                      required
                      autoFocus
                      style={{ borderRadius: "30px" }}
                    />

                    <button
                      onClick={(e) => this.handleSubmit(e)}
                      className="btn btn-lg btn-primary btn-block text-uppercase my-4"
                      type="submit"
                    >
                      Signup
                    </button>
                  </form>
                  <h5 className="my-2">
                    {" "}
                    Already has an account. <Link to="/login"> Login</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Signup;
