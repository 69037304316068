import React from "react";

const TermsandCondition = () => {
  return (
    <div className="toc p-5">
      <h1>Terms and Conditions</h1>
      <h2>Cancellation</h2>
      <p>
        On receiving a request for cancellation of the Quiz, we will refund your
        order amount within 2-8 business days from the day of requesting for
        cancellation, depending upon your mode of payment.
      </p>
      <h3>Cancellation Criteria</h3>
      <ul>
        <li>
          You have made payment for the Online Quran Competition test and you
          are not willing to continue without trying a Question.
        </li>
        <li>
          This cancellation will not be valid if even a Question of the test is
          completed or the time out notice is given for a question which means
          you have tried the Question however you don’t know the answer and you
          want cancellation and refund. This is not acceptable.
        </li>
        <li>
          You have tried to join the Quiz but due to the network service is poor
          and you cant reach to the stage of Questions after making the payment.
        </li>
        <li>
          There is case of fraud transaction made by any unknown person from
          your account and this is acknowledged by your bank to us with clear
          information. If this is the case then we will see the merit of the
          case and process for refund.
        </li>
      </ul>
      <h2>Refunds</h2>
      <p>
        Once the refund is approved, it will take 2-8 business days for you to
        receive the order amount based on your mode of payment.
      </p>
      <ul>
        <li>
          For UPI payments like Phone Pay, Paytm, Google pay, Credit/Debit
          Card/Net Banking/Wallets - 2-4 business days
        </li>
        <li>
          If the transaction is done through NEFT,then the Quran Quiz applicant
          needs to share the below-mentioned details at our e-mail{" "}
        </li>
        <li>
          ID onlineCare@thequrancompetition.com from their registered e-mail ID:{" "}
        </li>
        <p>1. Account number</p>
        <p>2. Account Holder's name</p>
        <p>3. IFSC Code</p>
        <li>
          After receiving the required information for a refund, the refund will
          be processed in 2 business days, and the same will reflect
        </li>
      </ul>
      <h2>Prize Distribution</h2>
      <ul>
        <li>
          Prize distribution day will be made to know the moment you completed
          the Quiz.
        </li>
        <li>
          The moment you complete the Quiz there will be prompt to let you know
          the date of prize distribution which may change from time to time.
        </li>
      </ul>
    </div>
  );
};

export default TermsandCondition;
