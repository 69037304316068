import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  return (
    <>
      <footer
        className="footer-custom section footer-classic context-dark bg-image text-white"
        style={{
          backgroundColor: "rgb(15, 57, 45)",
          position: "",
          bottom: "0",
          width: "100vw",
        }}
      >
        <div className="">
          <div className="row row-30">
            <div className="col-md-4 col-xl-5">
              <div className="pr-xl-4">
                <p>
                  We developing plateform to conduct both Online and Offline
                  Quran Competition in India and International level.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <h5>Contacts</h5>
              <dl className="contact-list">
                <dt>Address:</dt>
                <dd>
                  Kshetri Bengoon Awang Leikai, Porompat, District-Imphal
                  East-795008, State-Manipur, India
                </dd>
              </dl>
              <dl className="contact-list">
                <dt>email:</dt>
                <dd>
                  <a href="mailto:#">Jannatfoundation786@gmail.com</a>
                </dd>
              </dl>
              <dl className="contact-list">
                <dt>phones:</dt>
                <dd>
                  <a href="tel:9863015792">9863015792</a>
                </dd>
              </dl>
            </div>
            <div className="col-md-4 col-xl-3">
              <h5>Links</h5>
              <ul className="nav-list">
                <li>
                  <a href="/toc">TermsandCondition</a>
                </li>
              </ul>
              <ul className="nav-list">
                <li>
                  <a href="/aboutus">About Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
