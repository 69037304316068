import React from 'react'

const AboutUs = () => {
  return (
    <div className='toc p-5'>
	<title>About Us - Jannat Foundation</title>
	<h1>About Us</h1>
	<p>We are running a Trust known as “JANNAT FOUNDATION”. We are developing a platform to conduct both Online and Offline Quran Competitions in India and International level. Our Trust has the following objectives apart from the Quran competition:</p>
	<ol>
		<li className='p-2'>To work for the upliftment of human kind in general and minorities in particular.</li>
		<li className='p-2'>To work for the advancement of Education, Art, Science and health under Article 25, 26, 29 and 30(i) of the Constitution of India.</li>
		<li className='p-2'>To provide Sadaka Jariya / monetary aid to the poor, destitute, sick and disabled persons, orphans, widows and people of distress due to human or natural calamities.</li>
		<li className='p-2'>To establish, promote, set up, maintain, or maintaining and or running Educational platforms for both Worldwide Online and Offline Quran Competition and to offer Monetary reward and scholarship for the competitors. The competitors will subscribe a small fee as registration fee for the Quran competition which will be accumulated to the bank account open for this Trust. Registration fee may come from both national (India) and International as this is an International competition.</li>
		<li className='p-2'>To help youth to acquire knowledge skills to awaken social consciousness & responsibility. To organize debate, contest, speech on Mass Quran Competition.</li>
		<li className='p-2'>To establish, promote and set up, maintain, or maintaining and or running Educational Institutions for CBSE School, State board schools, state higher secondary schools and college, Madrassa and other such institutions under Article 29 and 30(i) of the Indian Constitution or provide monetary aid to such institutions if deemed necessary.</li>
		<li className='p-2'>To established, promote and set up, maintain, or maintaining and or running Educational Institutions and Coaching Institutes for Civil Service and Conduct Civil Service Question Type Competition both offline and online and to offer reward and scholarship for the competitors.</li>
		<li className='p-2'>To establish, promote and set up, maintain, or maintaining and or running Educational Institutions of Maktabs, Madrassas, Masjid and Minorities Girls’ Madrassa and Girls’ General and Technical School and College.</li>
		<li className='p-2'>To undertake Projects, Programmes and Schemes relating to technical and professional education, Scientific enquiry and research and to establish, maintain, and administer academic institutions under Art 30(i) of the Constitution of India for imparting Modern Technical and Professional education to the deserving youth such as institute of Science and Technology, Polytechnic, Information Technology Computer educational institute, Medical College and Hospital Dental College and Hospital, Research Centre, Training Institutes and other Institutions of similar nature so that the deserving youth after being trained at these institutions may get employment in their respective fields and thereafter they may substantially contribute to the development of the nation as worthy sons and daughters of India.</li>
		<li className='p-2'>To grant, pay or give scholarship, stipends prizes, rewards, allowances and other financial assistance or help in cash or kind to students or to the competitors. To run, establish library and reading rooms in needy communities.</li>
	</ol>
    </div>
  )
}

export default AboutUs